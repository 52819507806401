/**
 * Timetastic
 * Annual leave tracker page
 */
import React, { useEffect } from "react"
// import { Link } from "gatsby"
import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage, BgImage } from "gbimage-bridge"
import { BackgroundImage } from "gatsby-background-image"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import BackgroundBlobs from "../components/images/BackgroundBlobs"
import FloatingImages from "src/components/floatingImages"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
// import FreeTrial from "src/components/freeTrial"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"

// Styles
import "src/styles/templates/landing.scss"
import "src/styles/animations/landing.scss"
// import "src/styles/templates/rewards.scss"

// SEO
const title =
  "Limitless rewards when you refer! Earn a £50 gift card for each referral."
const description =
  "Refer a fellow business owner to Timetastic and you’ll earn a £50 Amazon.co.uk gift card when they sign up. "

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO title={title} description={description} />
      <Layout>
        <Header />
        <FooterCta />
        <main>
          <section
            className="c-section c-landing-screenshots is-unanimated c-landing-content c-landing-bullet"
            id="screenshots"
          >
            <div className="u-inner u-inner--l c-landing-screenshots__inner">
              <div>
                <h1 className="h h2 c-landing-screenshots__title">
                  Limitless rewards when you refer
                </h1>
                <h2 className="h h3 u-bgcolor-gradient u-bgcolor-gradient-text">
                  Earn a £50 gift card
                  <br />
                  for each referral
                </h2>
                <br />
                <p>
                  Ready to turn your network into unlimited rewards?
                  <br />
                  You got it!
                </p>
                <p>
                  Refer a fellow business owner to Timetastic — whether it’s a
                  friend, supplier, or even a client of yours — and you’ll earn
                  a £50 Amazon.co.uk gift card when they sign up.
                  <br />
                  It’s as simple as that.{" "}
                </p>
                <ol>
                  <li>
                    <a
                      aria-label="Sign up to Rewardful"
                      className="u-em-link"
                      href="https://timetastic.getrewardful.com/signup"
                      rel="noopener"
                      target="_blank"
                      title="Opens in a new window"
                      onClick={() => {
                        trackCta("RewardsSignupLink")
                      }}
                    >
                      Sign up to Rewardful
                    </a>
                    .
                  </li>
                  <li>Receive your unique referral URL.</li>
                  <li>
                    Share your URL with businesses you think could benefit from
                    Timetastic.
                  </li>
                  <li>
                    Sit back and relax as we track any sign-ups through your
                    link.
                  </li>
                  <li>
                    Earn a £50 Amazon voucher when your referral signs up and
                    becomes a paying user within the first 4 months.
                  </li>
                </ol>
                <p>
                  The best part? There’s no limit to how many referrals you can
                  make. So, the more you refer, the more you can earn!
                </p>
                <div className="c-landing-cta">
                  <a
                    aria-label="Sign up to Rewardful"
                    className="c-button c-button--xl c-button--primary"
                    href="https://timetastic.getrewardful.com/signup"
                    rel="noopener"
                    target="_blank"
                    title="Opens in a new window"
                    onClick={() => {
                      trackCta("RewardsSignupButton")
                    }}
                  >
                    Refer now
                  </a>
                  <p></p>
                </div>
              </div>
              <div>
                <BackgroundBlobs blobType="holidayCalcBlobs">
                  <FloatingImages></FloatingImages>
                </BackgroundBlobs>
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default

export const query = graphql`
  query {
    placeholderImage: file(
      relativePath: { eq: "../images/hero-blobs-landscape.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 100, formats: [AUTO, WEBP])
      }
    }
  }
`
