import React, { useEffect, useState, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "./floatingImages.scss"

const FloatingImages = () => {
  const containerRef = useRef(null)
  const [images, setImages] = useState([])

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "floatingImages" }
          extension: { regex: "/(jpg|jpeg|png|webp|avif)/" }
        }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 240
              height: 240
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF] # Optimized formats
            )
          }
        }
      }
    }
  `)

  const imageNodes = data.allFile.nodes

  useEffect(() => {
    const preloadImage = (imageData) => {
      const img = new Image()
      img.src = imageData.images.fallback.src // Preload fallback image
    }

    // Preload first two images
    imageNodes.slice(0, 2).forEach((node) => {
      const imageData = getImage(node.childImageSharp)
      preloadImage(imageData)
    })

    const createRandomImages = () => {
      const imageCount = Math.min(imageNodes.length, 9) // Use 9 or fewer images
      const possibleSizes = [8, 10, 12, 14, 16, 18, 20, 22, 24]
      const maxAttempts = 300
      const container = containerRef.current
      const containerWidth = container.clientWidth
      const containerHeight = container.clientHeight
      const centerX = containerWidth / 2
      const centerY = containerHeight / 2
      const maxDistanceFromCenter = containerWidth * 0.45

      const usedSizes = new Set()
      const selectedImages = []

      // Shuffle images for random selection
      const shuffledImages = [...imageNodes].sort(() => 0.5 - Math.random())

      for (let i = 0; i < imageCount; i++) {
        const imageNode = shuffledImages[i]
        const gatsbyImageData = getImage(imageNode.childImageSharp)

        let sizePercent
        do {
          sizePercent =
            possibleSizes[Math.floor(Math.random() * possibleSizes.length)]
        } while (usedSizes.has(sizePercent))
        usedSizes.add(sizePercent)

        const sizePx = (sizePercent / 100) * containerWidth

        let top, left
        let positionValid
        let attempts = 0

        do {
          positionValid = true

          // Generate a random position within the clustering radius around the center
          const angle = Math.random() * 2 * Math.PI
          const distance = Math.random() * maxDistanceFromCenter
          left = centerX + distance * Math.cos(angle) - sizePx / 2
          top = centerY + distance * Math.sin(angle) - sizePx / 2

          // Ensure the image is entirely within the container bounds
          if (
            left < 0 ||
            top < 0 ||
            left + sizePx > containerWidth ||
            top + sizePx > containerHeight
          ) {
            positionValid = false
            continue
          }

          // Check for overlap with existing images
          for (const otherImage of selectedImages) {
            const distanceBetweenCenters = Math.hypot(
              left - otherImage.left,
              top - otherImage.top
            )
            if (distanceBetweenCenters < (sizePx + otherImage.size) / 2 + 20) {
              positionValid = false
              break
            }
          }

          attempts++
        } while (!positionValid && attempts < maxAttempts)
        // If no valid position found, skip this image
        if (attempts >= maxAttempts) continue

        selectedImages.push({
          imageData: gatsbyImageData,
          size: sizePx,
          top,
          left,
          floatX1: `-${Math.random() * 20}px`, // Adjust the float range as needed
          floatX2: `${Math.random() * 20}px`,
          floatY1: `-${Math.random() * 20}px`,
          floatY2: `${Math.random() * 20}px`,
          floatDuration: `${8 + Math.random() * 8}s`,
        })
      }

      setImages(selectedImages)
    }

    createRandomImages()
  }, [imageNodes])

  return (
    <div ref={containerRef} className="c-rewards-hero__images">
      {images.map((img, index) => (
        <div
          key={index}
          className="image-wrapper"
          style={{
            width: `${img.size}px`,
            height: `${img.size}px`,
            top: `${img.top}px`,
            left: `${img.left}px`,
            animationDuration: img.floatDuration,
            "--float-x1": img.floatX1,
            "--float-x2": img.floatX2,
            "--float-y1": img.floatY1,
            "--float-y2": img.floatY2,
          }}
        >
          <GatsbyImage
            image={img.imageData}
            alt={`Floating image ${index + 1}`}
            className="image"
            loading="lazy"
          />
        </div>
      ))}
    </div>
  )
}

export default FloatingImages
